const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpspatna/13.png",
    text: "13",
  },
];

export default data;
