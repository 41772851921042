import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Delhi Public School, Patna",
  address: "Patna, Bihar",
  client: "Sanskriti Global Educational Trust",
  area: "2,85,000 Sq. Ft.",
  project: "Delhi Public School, Patna (Winning Design Entry)",
  category: "Institutions | Architecture | School",
  status: "Phase 1 Under Construction",
  backlink: "/projects/institutions/",
};

export default class Projectdpsfaridabad extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Institutional Projects | Delhi Public School, Patna"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the award-winning design and site plans of the DPS Patna campus currently under construction. "
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
